import React, { useEffect } from "react";
import Container from "../../../components/layouts/Container";
import GoogleCaptcha from "../../../components/common/GoogleCaptcha";
import BreadCrumb from "../../../components/elements/BreadCrumb";
import ModuleAboutUs from "./modules/ModuleAboutUs";
import ModuleTestimonial from "./modules/ModuleTestimonial";
import ModuleKeyPoints from "./modules/ModuleKeyPoints";
import ModuleWhyUs from "./modules/ModuleWhyUs";

const AboutUsOne = () => {
  /*
    To breadcrumb
  */
  const breadcrumb = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "AboutUs",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <GoogleCaptcha />
      <div id="aboutusid">
        <div className="container">
          <div className="ps-page__header">
            <BreadCrumb breacrumb={breadcrumb} />
            {/* <h1 className="ps-page__heading"> About Us</h1> */}
          </div>
        </div>

        <div className="ps-page form-loader">
          <div className="ps-section__overlay">
            <div className="ps-section__loading"></div>
          </div>
          <div className="home-background-image">
            <div className="ps-page ps-page--product home-section-div">
              <div className="container">
                <div className="ps-page__content">
                  <div className=" ps-reverse">
                    <div className="ps-layout__right">
                      <div className="aboutus-one">
                        <div className="title">
                          <h2>About Us</h2>
                          <div className="title-line"></div>
                        </div>
                        <ModuleAboutUs />
                        <ModuleWhyUs />
                        <ModuleTestimonial />
                        {/* <ModuleKeyPoints /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default AboutUsOne;
