import React from "react";
import { connect } from "react-redux";

import ProductOnCart from "../../../components/elements/products/ProductOnCart";
import useProductHook from "../../../hooks/useProductHook";
import ProductVariantModal from "../../shared/modals/ProductVariantModal";

const ModuleEcomerceWishlist = ({ ecomerce, product, wishlistevent = "", currencyrate }) => {
  const {
    isVarientobject,
    show,
    productItem,
    variantArray,
    groupedVariantArray,
    handleClose,
    handleRemoveItem,
    addToCart,
    setSelectedVariant,
    handleAddItemToCartFromWishlist,
  } = useProductHook(product, currencyrate, ecomerce);

  return (
    <>
      <table id="tablewishlist" className="table ps-table ps-table--shopping-cart ps-table--responsive">
        {product && product.length > 0 ? (
          <tbody>
            {product.map((item) => (
              <tr key={item.id}>
                <td className="wishlist-td-productname text-left">
                  <ProductOnCart product={item} currencyrate={currencyrate} />
                </td>
                <td className="wishlisttd text-center align-self-center order-space-mobile">
                  {item.productstatus === "unpublished" ? (
                    <div className="ps-status outstock">
                      <i className="fa fa-close mr-2"></i>
                      Not available
                    </div>
                  ) : (
                    <>
                      {item.productstockavailable === "YES" ? (
                        <div className="ps-status instock">
                          <i className="fa fa-check mr-2"></i>
                          In Stock
                        </div>
                      ) : (
                        <div className="ps-status outstock">
                          <i className="fa fa-close mr-2"></i>
                          Sold Out
                        </div>
                      )}
                    </>
                  )}
                </td>
                <td
                  className={
                    wishlistevent === "mywishlist"
                      ? "wishlisttd text-center align-self-center"
                      : "vertical-align-middle text-center align-self-center"
                  }
                  style={{ width: "200px" }}
                >
                  <div className="row">
                    <div className="col-12">
                      {item.productstockavailable === "YES" && item.productstatus === "published" ? (
                        <a
                          className="ps-btn ps-btn--sm font-size-16"
                          href="#"
                          onClick={(e) => handleAddItemToCartFromWishlist(e, item)}
                        >
                          Move to cart
                        </a>
                      ) : (
                        <a className="ps-btn ps-btn--sm disabled disable-btn-color font-size-16" href="#">
                          Move to cart
                        </a>
                      )}
                    </div>
                  </div>
                </td>
                <td className="wishlisttd text-center align-self-center" style={{ width: "50px" }}>
                  <a className="ps-icon ps-cart-item__remove" href="#" onClick={(e) => handleRemoveItem(e, item.id)}>
                    <i className="fa fa-trash mr-2 color-red"></i>
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td colSpan={5} className="text-left">
                No product found.
              </td>
            </tr>
          </tbody>
        )}
      </table>
      <ProductVariantModal
        show={show}
        handleClose={handleClose}
        productItem={productItem}
        isVarientobject={isVarientobject}
        currencyrate={currencyrate}
        variantArray={variantArray}
        groupedVariantArray={groupedVariantArray}
        setSelectedVariant={setSelectedVariant}
        product={product}
        addToCart={addToCart}
      />
    </>
  );
};

export default connect((state) => state)(ModuleEcomerceWishlist);
