import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link, useLocation, useSearchParams } from "react-router-dom";

import config from "../../../config/config.json";
import shopconfig from "../../../config/shopconfig.json";
import useGetProducts from "../../../hooks/useGetProducts";
import useEcomerce from "../../../hooks/useEcomerce";
import useHeader from "../../../hooks/useHeader";

const HeaderOptionOne = ({ ecomerce }) => {
  const {
    isSticky,
    hideNavTwo,
    homedata,
    getHomeData,
    username,
    login,
    profile,
    isSearchOpen,
    toggleSearch,
    keyword,
    product,
    isProductListVisible,
    setIsProductListVisible,
    navigate,
    setKeyword,
    setIsSearchOpen,
    handleSetKeyword,
    handleSubmit,
    handleOpenDrawer,
  } = useHeader();
  const { mainmenu, getSiteMainMenu } = useGetProducts();
  const { wishlistandcartCount, getWishlistAndCartCount } = useEcomerce();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  useEffect(() => {
    getSiteMainMenu();
    getHomeData();
    if (username) {
      getWishlistAndCartCount();
    }
  }, [ecomerce, keyword, searchParams]);

  useEffect(() => {
    let searchkey = searchParams.get("searchkey");
    if (searchkey) {
      setKeyword(searchkey);
      setIsSearchOpen(true);
    }
  }, []);

  useEffect(() => {
    if (!isSearchOpen) {
      setKeyword("");
      setIsProductListVisible(false);
    } else {
      const searchKey = searchParams.get("searchkey");
      if (searchKey && keyword === "") {
        setKeyword(searchKey);
      }
    }
  }, [isSearchOpen, searchParams, keyword]);

  /*
    To view
  */
  let productlist = [];
  let imageURL, imagepath;
  if (keyword !== null && keyword !== "") {
    if (product && product !== null && product !== "" && product.length > 0) {
      product.map((item) => {
        imagepath = config.apiUrl + config.image_path;
        if (item.imagename) {
          imageURL = `${imagepath}${item.imagename}`;
        } else {
          imageURL = "/static/img/noproduct-images.png";
        }
        productlist.push(
          <li>
            <a href={`/product/${item.uniqueid}`}>
              <div className="d-flex align-items-center">
                <div className="col-2 p-0">
                  <img src={imageURL} alt="" className="search-image" />
                </div>
                <div className="col-10 p-0">
                  <p>{item.productname}</p>
                </div>
              </div>
            </a>
          </li>
        );
      });
    } else {
      productlist.push(<li className="text-center">No Product Found</li>);
    }
  }

  const handleClearSearch = () => {
    const params = new URLSearchParams(location.search);
    params.delete("searchkey");
    setKeyword("");
    navigate("/shop?pageno=1&searchkey=");
  };

  const handleInputChange = (e) => {
    handleSetKeyword(e);
    const inputKeyword = e.target.value;
    setKeyword(inputKeyword);
    if (inputKeyword === "") {
      const params = new URLSearchParams(location.search);
      params.delete("searchkey");
      navigate(`/shop?pageno=1`);
      setIsProductListVisible(false);
    } else {
      setIsProductListVisible(true);
    }
  };

  return (
    <>
      <div className={`header-option--one ${isSticky ? "sticky" : ""}`}>
        {location.pathname === "/" && homedata?.homedetail && homedata.homedetail.topbartitle && (
          <div className="announcements">
            <div className="text-center">
              <div className="announcement">{homedata.homedetail.topbartitle}</div>
            </div>
          </div>
        )}
        <div className="nav-one">
          <div className="container">
            <div className="track-order">
              <div className="display-flex-center">
                <div className="icon-container" onClick={profile}>
                  <i class="fi fi-rr-phone-call"></i>
                </div>
                <div className="details">
                  <a href={`tel: ${shopconfig.shop_contactno}`}>
                    <div>{shopconfig.shop_contactno}</div>
                  </a>
                </div>
              </div>
            </div>
            <div className="logo">
              <Link to={"/"}>
                <img src={shopconfig.shop_logo} />
              </Link>
            </div>
            <div className="action-container">
              <div className={`icon-container search-icon ${isSearchOpen ? "active" : ""}`} onClick={toggleSearch}>
                <i className="fi fi-rr-search"></i>
                {isSearchOpen && (
                  <form onSubmit={(e) => handleSubmit(e)}>
                    <input
                      type="text"
                      className="search-input"
                      placeholder="Search..."
                      autoFocus
                      onChange={handleInputChange}
                      value={keyword}
                    />
                    {keyword && (
                      <button type="button" className="close-search" onClick={handleClearSearch}>
                        ✕
                      </button>
                    )}
                    {keyword && isProductListVisible && (
                      <div className="product-search-header-one">
                        <ul>{productlist}</ul>
                      </div>
                    )}
                  </form>
                )}
              </div>
              {username && username !== null && username !== "" ? (
                <div className="icon-container" onClick={profile}>
                  <i class="fi fi-rr-user"></i>
                </div>
              ) : (
                <div className="icon-container" onClick={login}>
                  <i class="fi fi-rr-user"></i>
                </div>
              )}
              <div className="icon-container">
                <Link to={"/wishlist"}>
                  <i class="fi fi-rr-heart"></i>
                  {wishlistandcartCount.totalwishlistcount > 0 && (
                    <span className="badge">{wishlistandcartCount.totalwishlistcount}</span>
                  )}
                </Link>
              </div>
              <div className="icon-container mr-0">
                <Link to="#" className="header__action" onClick={(e) => handleOpenDrawer(e)}>
                  <i class="fi fi-rr-shopping-cart"></i>
                  {wishlistandcartCount.totalcartcount > 0 && (
                    <span className="badge">{wishlistandcartCount.totalcartcount}</span>
                  )}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className={`nav-two ${hideNavTwo ? "nav-two-hide" : ""}`}>
          <div className="container">
            <div className="menu">
              <ul>
                {mainmenu &&
                  Array.isArray(mainmenu) &&
                  mainmenu.length > 0 &&
                  mainmenu.map((menuItem, index) => (
                    <li key={index}>
                      <Link to={menuItem.url}>{menuItem.menuname}</Link>
                      {menuItem.subcategories &&
                        menuItem.displaysubcategory === 1 &&
                        Array.isArray(menuItem.subcategories) &&
                        menuItem.subcategories.length > 0 && (
                          <div className="submenu--simple">
                            {menuItem.subcategories.map((subCategory, subIndex) => (
                              <div className="submenu-simple" key={subIndex}>
                                <ul>
                                  <li key={subIndex}>
                                    <Link to={`/category/${subCategory.url}`}>{subCategory.categoryname}</Link>
                                  </li>
                                  {subCategory.items &&
                                    Array.isArray(subCategory.items) &&
                                    subCategory.items.length > 0 &&
                                    subCategory.items.map((subItem, subItemIndex) => (
                                      <li key={subItemIndex}>
                                        <Link to={subItem.url}>{subItem.name}</Link>
                                      </li>
                                    ))}
                                </ul>
                              </div>
                            ))}
                          </div>
                        )}
                    </li>
                  ))}
                {/* <li>
                Megha Menu
                <div className="submenu">
                  <div className="submenu--one">
                    <div className="submenu-img">
                      <img src="/static/img/category/women/c3_1.jpg" />
                    </div>
                    <div className="submenu-list">
                      <div className="list">
                        <ul>
                          Men
                          <li>Jeans</li>
                          <li>T-shirts</li>
                          <li>Shirts</li>
                          <li>Trousers</li>
                          <li>Casuals</li>
                        </ul>
                      </div>
                      <div className="list">
                        <ul>
                          Women
                          <li>Tops</li>
                          <li>T-shirts</li>
                          <li>Dress</li>
                          <li>Formals</li>
                          <li>Casuals</li>
                          <li>Skirts</li>
                          <li>Formals</li>
                          <li>Casuals</li>
                          <li>Skirts</li>
                        </ul>
                      </div>
                      <div className="list">
                        <ul>
                          Children
                          <li>Toys</li>
                          <li>Accessories</li>
                          <ul className="p-0">
                            Boy
                            <li>Shirts</li>
                            <li>T-shirts</li>
                            <li>Shorts</li>
                          </ul>
                          <ul className="p-0">
                            Girl
                            <li>Shirts</li>
                            <li>T-shirts</li>
                            <li>Shorts</li>
                          </ul>
                        </ul>
                      </div>
                    </div>
                    <div className="submenu-category-card">
                      <div class="card">
                        <img src="/static/img/category/women/c3_5.jpg" />
                      </div>
                      <div class="card">
                        <img src="/static/img/category/women/c3_4.jpg" />
                      </div>
                      <div class="card">
                        <img src="/static/img/category/women/c3_2.jpg" />
                      </div>
                      <div class="card">
                        <img src="/static/img/category/women/c3_3.jpg" />
                      </div>
                    </div>
                  </div>
                </div>
              </li> */}
              </ul>
            </div>
            <div className="display-flex-center">
              <div className="icon-only" onClick={profile}>
                <i class="fi fi-rr-marker primary"></i>
              </div>
              <Link to={"/trackmyorder"}>
                <div>Track Order</div>
              </Link>
            </div>
          </div>
        </div>
        <hr />
      </div>
    </>
  );
};

export default connect((state) => state)(HeaderOptionOne);
