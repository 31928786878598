import React from "react";
import { Link } from "react-router-dom";

import shopconfig from "../../../config/shopconfig.json";

const Logo = ({ url = "/", type = "default", height = "70" }) => {
  if (type == "white") {
    return (
      <Link to={url}>
        <span className="ps-logo">
          <img src={shopconfig.shop_logo_white} alt="logo" height={height} />
        </span>
      </Link>
    );
  } else {
    return (
      <Link to={url}>
        <span className="ps-logo">
          <img src={shopconfig.shop_logo} alt="logo" height={height} />
        </span>
      </Link>
    );
  }
};

export default Logo;
