import React from "react";
import { Link } from "react-router-dom";

import shopconfig from "../../../../config/shopconfig.json";
import FooterLinks from "../../../../public/static/data/footer.json";
import Logo from "../../../elements/basic/Logo";
import useHeader from "../../../../hooks/useHeader";

const FooterOptionTwo = () => {
  const { username } = useHeader();
  return (
    <>
      <div className="footer-option-two">
        <div className="row m-0 d-flex justify-content-between content">
          <div className="col-12 col-md-3 col-sm-12 logocontainer text-center">
            <Logo type="white" height="100"></Logo>
          </div>
          <div className="col-12 col-md-3 d-flex flex-column">
            <h3 className="f-two-title">About Store</h3>
            <p className="f-two-info">{shopconfig.about}</p>
            <div className="f-two-address-container">
              <div className=" ">
                <a href={shopconfig.maplink} className="f-two-icons">
                  <i class="fi fi-rr-marker"></i>
                </a>
              </div>
              <p>
                {shopconfig.shop_address} {shopconfig.shop_city} {shopconfig.shop_pincode} {shopconfig.shop_state},{" "}
                {shopconfig.shop_country}
              </p>
            </div>
          </div>
          <div className="col-6 col-md-2 f-two-link">
            <h3 className="f-two-title">{FooterLinks.information.title}</h3>
            <ul className="pl-0">
              {FooterLinks.information.items.map((item) => (
                <Link to={item.url}>
                  <li className="f-two-li">{item.text}</li>
                </Link>
              ))}
            </ul>
          </div>
          <div className="col-6 col-md-2 f-two-link">
            <h3 className="f-two-title">{FooterLinks.account.title}</h3>
            <ul className="pl-0">
              {FooterLinks.account.items.map((item) => (
                <Link to={item.url !== "/profile" ? item.url : username !== "" ? "/profile" : "/login"}>
                  <li className="f-two-li">{item.text}</li>
                </Link>
              ))}
            </ul>
          </div>
        </div>

        <hr />
        <div className="row m-0 d-flex justify-content-between flex-md-row ">
          <div className="col-12 col-md-3 col-sm-12 d-flex flex-column">
            <h3 className="f-two-title">Give us a call</h3>
            <div className="f-two-social-container">
              <div className="icon-contrainer rounded">
                <i class="fi fi-rr-phone-call"></i>
              </div>
              <span>
                <a href={`tel: ${shopconfig.shop_contactno}`}>{shopconfig.shop_contactno}</a>
              </span>
            </div>
          </div>
          <div className="col-12 col-md-3 col-sm-12 d-flex flex-column">
            <h3 className="f-two-title">Email us</h3>
            <div className="f-two-social-container">
              <div className="icon-contrainer rounded">
                <i class="fi fi-rr-envelope"></i>
              </div>
              <span>
                <a href={`mailto: ${shopconfig.email}`}>{shopconfig.email}</a>
              </span>
            </div>
          </div>
          <div className="col-6 col-md-3 d-flex col-sm-12 flex-column">
            <h3 className="f-two-title">Follow us</h3>
            <div className="f-two-social-container">
              {shopconfig.facebook && (
                <a href={shopconfig.facebook}>
                  <img src="/static/img/social/facebook.png" className="f-two-icons" />
                </a>
              )}
              {shopconfig.twitter && (
                <a href={shopconfig.twitter}>
                  <img src="/static/img/social/twitter.png" className="f-two-icons" />
                </a>
              )}
              {shopconfig.linkedin && (
                <a href={shopconfig.linkedin}>
                  <img src="/static/img/social/linkedin.png" className="f-two-icons" />
                </a>
              )}
              {shopconfig.instagram && (
                <a href={shopconfig.instagram}>
                  <img src="/static/img/social/instagram.png" className="f-two-icons" />
                </a>
              )}
              {shopconfig.youtube && (
                <a href={shopconfig.youtube}>
                  <img src="/static/img/social/video.png" className="f-two-icons" />
                </a>
              )}
            </div>
          </div>
          <div className="col-12 col-md-3 col-sm-12 d-flex flex-column">
            <h3 className="f-two-title">Payment</h3>
            <img src="/static/img/payment.png" className="payment-img" height={50} width={260} />
          </div>
        </div>

        <hr />

        <div className="text-center copy-right-section">
          <span className="color-white" style={{ fontSize: 12, fontWeight: "lighter" }}>
            Copyright &copy; 2024. Developed and Managed By{" "}
            <a href="https://iconicussoft.com/" target="_blank">
              Iconicus Softwares Private Limited
            </a>
          </span>
        </div>
      </div>
    </>
  );
};

export default FooterOptionTwo;
