import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";

import useFormAction from "../../../hooks/useFormAction";

const PreFooterThree = () => {
  const { saveNewsLetter } = useFormAction();

  // Initial form values
  const initialValues = {
    subscribe: "",
  };

  // Validation schema
  const validationSchema = Yup.object().shape({
    subscribe: Yup.string().email("Please enter a valid email").required("Email is required"),
  });

  return (
    <div className="newsletter-three-container">
      <div className="icon">
        <img src="/static/img/icon/newsletter.png" alt="heart" />
      </div>
      <h2>Subscribe Newsletter</h2>
      <p>We advide you to do subscribe beacuse we don't want you to miss any update and benifits</p>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(value, { resetForm }) => {
          saveNewsLetter(value);
          document.getElementById("successmsg").classList.remove("hide");
          resetForm();
        }}
      >
        {(formik) => {
          const { errors, touched } = formik;
          if (errors.subscribe && touched.subscribe) {
            document.getElementById("successmsg").classList.add("hide");
          }

          return (
            <Form className="email-form">
              <div className="input-container">
                <Field
                  className={errors.subscribe && touched.subscribe ? "input-error form-control" : "form-control"}
                  type="email"
                  name="subscribe"
                  placeholder="Enter your email"
                  id="subscribefield"
                  onKeyUp={(e) => {
                    document.getElementById("successmsg").classList.add("hide");
                    e.preventDefault();
                  }}
                />
                <button type="submit">Subscribe</button>
              </div>
              <ErrorMessage name="subscribe" component="span" className="color-red mt-5" />
              <div className="row">
                <div className="col-12 color-green hide font-size-16 pl-20 mt-10" id="successmsg">
                  Your subscription has been added.
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>

      <p className="promise-text">We promise not to spam you!</p>
    </div>
  );
};

export default PreFooterThree;
