import React from "react";
import { connect } from "react-redux";

import ModuleBlog from "./ModuleBlog";
import ModuleBlogDescription from "./ModuleBlogDescription";
import SkeletonVendor from "../../../components/elements/skeletons/SkeletonVendor";

const ModuleBlogDetail = ({ blogdetail }) => {
  return (
    <div className="blog-detail">
      {blogdetail && blogdetail !== null && blogdetail !== "" && blogdetail.length > 0 ? (
        <>
          <ModuleBlog bloglist={blogdetail} isDetailPage={true} />
          <ModuleBlogDescription bloglist={blogdetail} />
        </>
      ) : (
        <SkeletonVendor />
      )}
    </div>
  );
};

export default connect((state) => state)(ModuleBlogDetail);
