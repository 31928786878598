import React from "react";

import shopconfig from "../../../../config/shopconfig.json";

const ModuleAboutUs = () => {
  return (
    <div className="aboutus-section--one">
      <div className="title">
        <h2>Hi, We are {shopconfig.shop_name}</h2>
        <div className="info">
          <p>
            At <span className="color-2nd">Plus 4 Fitness</span>, we are committed to empowering fitness enthusiasts and
            professionals with top-tier fitness equipment.{" "}
            <span className="color-2nd">With over a decade of experience </span> in the industry, we specialize in
            providing an extensive range of premium fitness products designed to meet the needs of home users,
            commercial gyms, and everything in between. Our passion lies in not only delivering high-quality equipment
            but also offering complete gym setup solutions, tailored to your unique vision. Whether you're starting a
            new gym or upgrading an existing facility, our team of seasoned experts is here to guide you every step of
            the way, from design to installation. What sets us apart is our dedication to exceptional after-sales
            service. We believe in building lasting relationships with our clients, ensuring their equipment runs
            smoothly long after the sale. Our goal is to revolutionize the fitness landscape by promoting health and
            wellness through reliable, high-performance equipment. At Plus 4 Fitness, we don’t just sell equipment—we
            help you create fitness experiences that last.
            <span className="color-2nd"> Let’s elevate your fitness journey together! </span>
          </p>
        </div>
      </div>
      <div className="img-container">
        <img src="/static/img/static_image/about_us.png" />
      </div>
    </div>
  );
};

export default ModuleAboutUs;
