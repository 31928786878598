import Repository, { baseUrlProduct } from "./Repository";
import config from "../config/config.json";
import axios from "axios";

const headers = {
  Accept: "*/*",
};

class CollectionRepository {
  constructor(callback) {
    this.callback = callback;
  }

  async getProductsByCollectionSlug(slug) {
    const reponse = await Repository.get(`${baseUrlProduct}/collections?slug_in=${slug}`)
      .then((response) => {
        if (response.data && response.data.length > 0) {
          return { items: response.data[0].products };
        } else {
          return null;
        }
      })
      .catch((error) => {
        return null;
      });
    return reponse;
  }

  /*
   To get product by collection id
  */
  async getProductByCollectionId(slug, pageno = 1, filters = {}) {
    try {
      const url = config.apiUrl + config.apiurlname + "/collection/slug";

      const data = {
        slug,
        pageno: pageno,
        maximumprice: filters.maximumprice || "1000000",
        minimumprice: filters.minimumprice || "0",
        upperlimit: filters.upperlimit || 20,
        displayonlysale: filters.displayonlysale || "0",
        sortby: filters.sortby || null,
        category: filters.category || null,
        filters: filters.filters || null,
        rating: filters.rating || null,
        searchkey: filters.searchkey || null,
        tags: filters.tags || null,
      };

      const response = await axios.post(url, data, { headers });

      if (response && response.data) {
        return response.data.payload;
      }
    } catch (error) {
      if (error.response) {
        console.error("API Error:", error.response);
        return error.response.data;
      } else {
        console.error("Error:", error);
      }
    }
  }
}

export default new CollectionRepository();
