import React, { useEffect, useState } from "react";
import { Drawer } from "antd";

import ModuleSizeGuide from "./ModuleSizeGuide";
import useFilter from "../../../../../hooks/useFilter";

const ModuleVariants = ({ product, productvariant, setProduct, onVariantFlagChange }) => {
  const { isMenu, showFilters, closeFilters } = useFilter();

  const [selectedVariants, setSelectedVariants] = useState({});
  const [variantArray, setVariantArray] = useState([]);

  const handleVariantChange = (variantName, value) => {
    setSelectedVariants((prev) => ({ ...prev, [variantName]: value }));
  };

  const setVarientOptions = () => {
    onVariantFlagChange(false);
    if (productvariant && productvariant.length > 0) {
      const variantSet = new Set();

      productvariant.forEach((item) => {
        if (item.variantjson) {
          try {
            const variantjson = JSON.parse(item.variantjson);
            Object.keys(variantjson).forEach((key) => {
              const variant = { name: key, value: variantjson[key] };
              variantSet.add(JSON.stringify(variant));
            });
          } catch (error) {
            console.error("Error parsing variantjson:", error);
          }
        }
      });

      const newVariantArray = Array.from(variantSet).map((item) => JSON.parse(item));
      setVariantArray(newVariantArray);
    } else {
      onVariantFlagChange(true);
    }
  };

  useEffect(() => {
    setVarientOptions();
  }, [productvariant]);

  useEffect(() => {
    setVariantName();
  }, [selectedVariants]);

  const groupedVariantArray = Object.values(
    variantArray.reduce((acc, { name, value }) => {
      if (!acc[name]) {
        acc[name] = { name, values: [] };
      }
      acc[name].values.push(value);
      return acc;
    }, {})
  );

  const setVariantName = () => {
    let flag = false;

    productvariant.forEach((item) => {
      let variantJson = {};
      try {
        variantJson = JSON.parse(item.variantjson);
      } catch (error) {
        console.error("Error parsing variantjson:", error);
        return;
      }

      const checkedValue = Object.keys(variantJson)
        .map((key) => selectedVariants[key])
        .join("-");

      if (item.variantname === checkedValue) {
        setProduct((prevProduct) => ({
          ...prevProduct,
          productname: item.varianttitle,
          price: item.price,
          offerprice: item.offerprice,
          offertype: item.offertype,
          variantid: item.id,
          stock: item.stock,
          hsn: item.hsn,
          productvarientimagelist: item.productimagelist,
          alreadyincart: item.alreadyincart,
        }));

        flag = true;

        if (item.stock > 0 && item.price > 0) {
          onVariantFlagChange(true);
        } else {
          onVariantFlagChange(false);
        }
      }
      if (!flag) {
        setProduct((prevProduct) => ({
          ...prevProduct,
          stock: 0,
        }));
        onVariantFlagChange(false);
      }
    });

    if (!flag) {
      setProduct((prevProduct) => ({ ...prevProduct, stock: 0 }));
    }
  };

  return (
    <>
      <div className={`variant-container variant-container_${product.id} mb-20 mt-10`}>
        {groupedVariantArray.length > 0 &&
          groupedVariantArray.map((variantGroup, index) => (
            <div className="variant" key={index}>
              {variantGroup.name === "Size" ? (
                <>
                  <div className="d-flex justify-content-between">
                    <label className="name">
                      {variantGroup.name.charAt(0).toUpperCase() + variantGroup.name.slice(1)}:
                    </label>
                    {product.sizechart ? (
                      <label className="name drawer" onClick={showFilters}>
                        Size Chart
                      </label>
                    ) : (
                      ""
                    )}
                    {isMenu && (
                      <Drawer
                        className="ps-panel--mobile mobile-home"
                        closable={false}
                        placement="right"
                        onClose={closeFilters}
                        visible={isMenu}
                        width={600}
                      >
                        <ModuleSizeGuide closeDrawer={closeFilters} />
                      </Drawer>
                    )}
                  </div>
                </>
              ) : (
                <label className="name">
                  {variantGroup.name.charAt(0).toUpperCase() + variantGroup.name.slice(1)}:
                </label>
              )}
              <div className="radio-options">
                {variantGroup.values.map((value, idx) => (
                  <label key={idx} style={{ cursor: "pointer" }}>
                    <input
                      type="radio"
                      name={variantGroup.name}
                      value={value}
                      checked={selectedVariants[variantGroup.name] === value}
                      onChange={() => handleVariantChange(variantGroup.name, value)}
                      style={{ display: "none" }}
                    />
                    <span
                      style={{
                        backgroundColor: variantGroup.name === "Colour" ? value : "#fff",
                        border: selectedVariants[variantGroup.name] === value ? "2px solid #722c5b" : "1px solid gray",
                        background: variantGroup.name === "Colour" ? value : "#fff",
                      }}
                      className={`${variantGroup.name !== "Colour" ? "variant-circle" : "color-circle"}`}
                    >
                      {variantGroup.name !== "Colour" && value}
                    </span>
                  </label>
                ))}
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default ModuleVariants;