import { connect } from "react-redux";
import Slider from "react-slick";

import NextArrow from "../../components/elements/carousel/NextArrow";
import PrevArrow from "../../components/elements/carousel/PrevArrow";
import ProductLayoutTwoOptionTwo from "../../components/layouttwo/ProductLayoutTwoOptionTwo";

const SimilarProduct = ({ likeproduct, currencyrate }) => {
  /*
    To featured product view
  */
  let carouselitem = [];
  if (likeproduct && likeproduct.length > 0) {
    likeproduct.map((item, index) => {
      likeproduct.productid = item.id;
      carouselitem.push(
        <div className="carousel-item ps-layout--grid ps-shop-items bn shop-items-list producteffect">
          <div className="ps-category__item m-10">
            <div className="ps-layout__item card-box-shadow bn" key={index}>
              <ProductLayoutTwoOptionTwo product={item} currencyrate={currencyrate} />
            </div>
          </div>
        </div>
      );
    });
  }
  /*
    To carousel setting
  */
  const carouselSetting = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 5,
    dots: false,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  return (
    <>
      {carouselitem && carouselitem.length > 0 && (
        <div className="ps-page ps-page--product home-section-div">
          <div className="ps-page__content">
            <div className=" ps-reverse">
              <div className="ps-layout__right">
                <div className="row title-row">
                  <div className="col-12">
                    <h3>Products You May Like</h3>
                  </div>
                </div>
                <div className="row similar-slider-container">
                  <div className="col-12 p-0">
                    <Slider {...carouselSetting} className="ps-carousel home-slider">
                      {carouselitem}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default connect((state) => state)(SimilarProduct);
