import React from "react";

import config from "../../../../../config/config.json";

const ModuleCustomerReviewDrawer = ({ images, closeDrawer, productReview }) => {
  let imagepath = config.apiUrl + config.image_path;
  let noimage = "/static/img/static_image/user_image.png";

  return (
    <>
      <div className="review-header">
        <h3>All Reviews</h3>
        <i className="icon-cross" onClick={() => closeDrawer()}></i>
      </div>
      <hr className="m-0" />
      <div className="customer-review-drawer">
        {productReview &&
          productReview.productreviewlist !== null &&
          productReview.productreviewlist.map((review, index) => (
            <>
              <div className="userinfo-container">
                <div className="d-flex align-items-center">
                  <img src={review.imagename ? `${imagepath}${review.imagename}` : noimage} />
                  <div className="d-flex flex-column">
                    <span>
                      {review.firstname} {review.lastname}
                    </span>
                    <span className="post-time">Posted on: {review.reviewdate}</span>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  <div className="rating">{review.rating} ⭐</div>
                </div>
              </div>
              <div className="userreview-container">
                <p>{review.review}</p>
              </div>
              <hr className="mb-20 mt-20" />
            </>
          ))}
      </div>
    </>
  );
};

export default ModuleCustomerReviewDrawer;
