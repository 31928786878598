import React from "react";
import MetaTags from "react-meta-tags";

import HeaderOptionOne from "../../components/shared/headers/HeaderOptionOne";
import HeaderMobile from "../../components/shared/mobiles/HeaderMobile";
import PreFooterThree from "../../components/common/profooter/PreFooterThree";
import shopconfig from "../../config/shopconfig.json";
import FooterOptionTwo from "../shared/footers/layouttwo/FooterOptionTwo";
import HeaderMobileOne from "../shared/mobiles/HeaderMobileOne";

const Container = ({
  children,
  title = shopconfig.shop_title,
  footer = <FooterOptionTwo />,
  metatitle,
  metadesc,
  metakeyword,
  note,
}) => {
  /*
    To view
  */
  let titleView;
  if (title !== undefined) {
    titleView = process.env.title + " | " + title;
  } else {
    titleView = process.env.title + " | " + process.env.titleDescription;
  }

  return (
    <div className="ps-root" id="commonpagediv">
      <MetaTags>
        <title>{metatitle ? metatitle : title}</title>
        {metatitle && <meta name="title" content={`${metatitle}`}></meta>}
        {metadesc && <meta name="description" content={`${metadesc}`}></meta>}
        {metakeyword && <meta name="keyword" content={`${metakeyword}`}></meta>}
      </MetaTags>
      <HeaderOptionOne note={note} />
      <HeaderMobileOne note={note} />
      {children}
      <PreFooterThree />
      {footer}
    </div>
  );
};

export default Container;
