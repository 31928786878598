import { GoogleOAuthProvider } from "@react-oauth/google";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { Link } from "react-router-dom";
import * as Yup from "yup";

import auth from "../../api/auth";
import user from "../../api/user";
import GoogleCaptcha from "../../components/common/GoogleCaptcha";
import Logo from "../../components/elements/basic/Logo";
import FormikFrom from "../../components/elements/form/FormikFrom";
import SelectCombo from "../../components/elements/form/SelectCombo";
import Container from "../../components/layouts/Container";
import config from "../../config/config.json";
import useEcomerce from "../../hooks/useEcomerce";
import useLocalStorage from "../../hooks/useLocalStorage";
import LoginWithGoogle from "./LoginWithGoogle";

const clientid = config.clientid;
const facebook_appid = config.facebook_appid;
var countrycodearray = [];

/*
  To initial values json
*/
const initialValues = { username: "", password: "", mobileno: "", phonecountry: "+91" };
const data = {
  firstname: "",
  lastname: "",
  email: "",
  phonecountry: "0",
  phonenumber: "0000000000",
  status: "1",
  loginevent: "sociallogin",
};

/*
  To validate the login form
*/
const validationSchema = Yup.object().shape({
  password: Yup.string().required("Please enter value for Password"),
});

/*
  To form validation
*/
const validationSchemaMobilelogin = Yup.object().shape({
  mobileno: Yup.string()
    .required("Please enter value for Mobile no")
    .matches(/^[0-9]+$/, "Please enter valid mobile no")
    .min(10, "Please enter valid Mobile no"),
  phonecountry: Yup.string().required("Please enter value for Country Code"),
});

const Login = () => {
  const { blankLocalStorage } = useLocalStorage();
  const [disable, setDisable] = useState(false);
  const [verify, setVarify] = useState([true]);
  const [userName, setUser] = useState([true]);
  const [otpuser, setOtpuser] = useState([true]);
  const [otpEvent, setOtpEvent] = useState([true]);
  const [showErrorMsg, setshowErrorMsg] = useState([false]);
  const [ErrorMsg, setErrorMsg] = useState([false]);
  const [showErrorMsgLogin, setshowErrorMsgLogin] = useState(false);
  const [ErrorMsgLogin, setErrorMsgLogin] = useState(false);
  const [user_name, setUser_name] = useState();
  const [backbtn, setBackBtn] = useState("social");

  const { countrylist, getCountryList } = useEcomerce();
  const [selectedcountry, setSelectedCountry] = useState({ value: "+91", label: "+91" });

  /*
    To manage otp json
  */
  const otpform = {
    userid: localStorage.getItem("userid"),
    event: "Signin OTP",
    contactno: "",
    emailid: "",
  };

  /*
    To load the data at the time of screen initilization
  */
  useEffect(() => {
    if (localStorage.getItem("authToken")) {
      window.location.href = "/";
    } else {
      window.scrollTo(0, 0);
      setDisable(true);
      setTimeout(() => {
        setDisable(false);
        getCountryList();
      }, 500);
    }
  }, []);

  /*
    To set country in array
  */
  if (countrylist && countrylist.length > 0) {
    countrycodearray = [];
    countrylist.map((item, index) => {
      var countrycodeobject = new Object();
      countrycodeobject["value"] = item.extension;
      countrycodeobject["label"] = item.extension;
      countrycodearray.push(countrycodeobject);
    });
  }
  /*
    To login user
  */
  const loginUser = async (data) => {
    setshowErrorMsg(false);
    setErrorMsg("");
    setDisable(true);

    const res = await auth.login(data);

    if (res.status === 200) {
      blankLocalStorage();
      LocalStorage(res.data);
      setTimeout(() => {
        setDisable(false);
        window.location.href = "/";
      }, 500);
    } else {
      setDisable(false);
      setshowErrorMsg(true);
      setErrorMsg("Invalid Username / Password");
    }
  };

  /*
    To set local storage
  */
  const LocalStorage = (data) => {
    localStorage.setItem("authToken", data.access_token);
    localStorage.setItem("logindate", new Date());
    localStorage.setItem("expires_in", data.expires_in);
    localStorage.setItem("refresh_token", data.refresh_token);
  };

  /*
    To google response
  */
  const responseGoogle = (response) => {
    if (response !== null && response !== "") {
      data.firstname = response.given_name;
      data.lastname = response.family_name;
      data.email = response.email;
      createUser(data);
    }
  };

  /*
    To facebook response
  */
  const responseFacebook = (response) => {
    if (response !== null && response !== "") {
      if (response.name !== null && response.name !== "") {
        data.firstname = response.name.split(" ")[0];
        data.lastname = response.name.split(" ")[1];
      }
      data.email = response.email;
      createUser(data);
    }
  };

  /*
    To create user
  */
  const createUser = async (data) => {
    setDisable(true);
    blankLocalStorage();
    const res = await user.register(data);
    if (res.data.status === 200) {
      LocalStorage(res.data.payload);
      setTimeout(() => {
        setDisable(false);
        window.location.href = "/";
      }, 500);
    } else {
      setDisable(false);
    }
  };

  /*
    To show login with user div
  */
  const showEmail = () => {
    setshowErrorMsg(false);
    setErrorMsg("");
    setDisable(true);
    setTimeout(() => {
      setDisable(false);
    }, 500);
    document.getElementById("loginsocialdiv").classList.add("hide");
    document.getElementById("usernameform").classList.remove("hide");
    document.getElementById("loginform").classList.add("hide");
    document.getElementById("loginotpform").classList.add("hide");
    localStorage.removeItem("EventOtp");
    localStorage.setItem("phonecountry", "");
  };

  /*
    To show login with otp div
  */
  const showMobile = () => {
    setshowErrorMsg(false);
    setErrorMsg("");
    setDisable(true);
    setTimeout(() => {
      setDisable(false);
    }, 500);
    document.getElementById("loginsocialdiv").classList.add("hide");
    document.getElementById("loginform").classList.add("hide");
    document.getElementById("usernameform").classList.add("hide");
    document.getElementById("loginotpform").classList.remove("hide");
  };

  /*
    To login with otp using mobile
  */
  const loginUserWithOtp = async (data) => {
    var username = data.mobileno;
    if (username !== null && username !== "") {
      let regMobile = /^[0-9]{7,10}$/;
      if (!regMobile.test(username)) {
        setshowErrorMsg(true);
        setErrorMsg("Invalid Mobile Number");
      } else {
        setshowErrorMsg(false);
        setErrorMsg("");

        setUser_name(username);
        setOtpuser(username);

        setUser(username);
        setOtpEvent("contactno");
        localStorage.setItem("SendOtp", "contactno");
        localStorage.setItem("EventOtp", "loginotp");
        localStorage.setItem("otpfrom", username);
        localStorage.setItem("SendOtpOn", username);
        localStorage.setItem("phonecountry", data.phonecountry);

        otpform.contactno = username;
        otpform.userid = "";
        otpform.phonecountry = data.phonecountry;
        localStorage.setItem("event", "Signin OTP");
        const res = await user.sendOtpforLogin(otpform);
        if (res.data.status === 200) {
          setTimeout(() => {
            window.location.href = "/otpverification";
          }, 500);
        }
      }
    } else {
      setshowErrorMsg(true);
      setErrorMsg("Please enter value for Mobile Number");
    }
  };

  /*
    To  verify email or mobile
  */
  const varifyEmailORMobile = async () => {
    var username = document.getElementById("username").value;
    var object = new Object();
    var flag = true;
    if (username !== null && username !== "") {
      let regMobile = /^[0-9]{7,10}$/;

      if (regMobile.test(username)) {
        object["contactno"] = username;
      } else {
        let regemail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (regemail.test(username)) {
          object["emailid"] = username;
        } else {
          flag = false;
          setshowErrorMsgLogin(true);
          setErrorMsgLogin("Please enter valid Email / Mobile Number");
        }
      }
      if (flag) {
        const res = await user.verifyUserEmailORMobile(object);
        if (res.data.status === 200) {
          var phonecountry = res.data.payload.phonecountry;
          setUser(username);
          setUser_name(username);
          setOtpuser(username);
          setOtpEvent("emailid");
          localStorage.setItem("SendOtpOn", username);
          localStorage.setItem("SendOtp", "emailid");

          if (regMobile.test(username)) {
            object["contactno"] = username;
            setUser(phonecountry + username);
            setOtpEvent("contactno");
            localStorage.setItem("SendOtp", "contactno");
          }

          localStorage.setItem("userid", res.data.payload.userid);
          setVarify(false);
          setshowErrorMsgLogin(false);
          setErrorMsgLogin("");
          document.getElementById("usernameform").classList.add("hide");
          document.getElementById("loginform").classList.remove("hide");
          document.getElementById("loginotpform").classList.add("hide");

          setBackBtn("emailvarified");
        } else {
          setshowErrorMsgLogin(true);
          setErrorMsgLogin(res.data.errors.message);
        }
      }
    } else {
      setshowErrorMsgLogin(true);
      setErrorMsgLogin("Please enter Email / Mobile Number");
    }
  };

  /*
    To redirect to login page
  */
  const backlogin = () => {
    setDisable(true);
    setTimeout(() => {
      setDisable(false);
    }, 500);
    if (backbtn === "social") {
      document.getElementById("loginsocialdiv").classList.remove("hide");
      document.getElementById("usernameform").classList.add("hide");
      document.getElementById("loginform").classList.add("hide");
      document.getElementById("loginotpform").classList.add("hide");
    } else if (backbtn === "emailvarified") {
      setVarify(true);
      setBackBtn("social");
    }
  };

  /*
    To back to login user page
  */
  const backloginToUser = () => {
    setVarify(true);
    setBackBtn("social");
    setshowErrorMsg(false);
    setErrorMsg("");
    document.getElementById("loginsocialdiv").classList.add("hide");
    document.getElementById("usernameform").classList.remove("hide");
    document.getElementById("loginform").classList.add("hide");
    document.getElementById("loginotpform").classList.add("hide");
  };

  /*
    To resend otp on mobile/email
  */
  const sendOtp = async () => {
    if (otpEvent === "contactno") {
      otpform.contactno = otpuser;
    } else {
      otpform.emailid = otpuser;
    }

    otpform.userid = localStorage.getItem("userid");
    localStorage.setItem("event", "Signin OTP");
    var data = new Object();
    data["userid"] = otpform.userid;
    data["event"] = otpform.event;
    if (otpform.emailid) {
      data["emailid"] = otpform.emailid;
    }
    if (otpform.contactno) {
      data["contactno"] = otpform.contactno;
    }
    const res = await user.sendOtp(data);
    if (res.data.status === 200) {
      setTimeout(() => {
        window.location.href = "/otpverification";
      }, 500);
    }
  };

  /*
    To reset error
  */
  const resetError = () => {
    setshowErrorMsg(true);
    setErrorMsg("");
  };

  return (
    <Container>
      <GoogleCaptcha />
      <div className="ps-page form-loader">
        {disable && (
          <div className="ps-section__overlay">
            <div className="ps-section__loading"></div>
          </div>
        )}

        <div className="container">
          <div className="ps-page__header"></div>
          <div className="ps-page__content ps-account">
            <div className="row ml-0 mr-0 justify-content-center">
              <div className="content-card-login mt-100 mb-100 box-rounded-login login align-self-center">
                <div className="login-card-content gg-0">
                  <div className="br-1 login-welcome text-center login-mobile-hide ">
                    <div className="row height-100-per ">
                      <div className="col-12 ps-form--review form-padding">
                        <h3>Welcome To </h3>
                        <Logo />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="ps-form--review form-padding">
                      <h2 className="ps-form__title text-center">Login</h2>

                      <div className="row justify-content-center" id="loginsocialdiv">
                        <div className="col-12 col-md-12 text-center pt-10">
                          <a className={"social-btn"} onClick={showMobile} id="userotpbtn">
                            <div className="row">
                              <div className="col-3">
                                <img src="/static/img/icon/smartphone.png" className="icon mr-10"></img>
                              </div>
                              <div className="col-9 text-left pl-0 align-self-center">Login With OTP</div>
                            </div>
                          </a>
                        </div>

                        <div className="col-12 col-md-12 text-center pt-10">
                          <a className={"social-btn"} onClick={showEmail} id="userbtn">
                            <div className="row">
                              <div className="col-3">
                                <img src="/static/img/email.png" className="icon mr-10"></img>
                              </div>
                              <div className="col-9 text-left pl-0 align-self-center">Login With Username</div>
                            </div>
                          </a>
                        </div>

                        <div className="col-12 col-md-12 text-center pt-10">
                          <GoogleOAuthProvider clientId={clientid}>
                            <LoginWithGoogle responseGoogle={responseGoogle} />
                          </GoogleOAuthProvider>
                        </div>

                        <div className="col-12 col-md-12 text-center pt-10">
                          <FacebookLogin
                            appId={facebook_appid}
                            fields="name,email,picture"
                            render={(renderProps) => (
                              <a className="social-btn" onClick={renderProps.onClick}>
                                <div className="row">
                                  <div className="col-3">
                                    <img src="/static/img/facebook.png" className="icon mr-10"></img>
                                  </div>
                                  <div className="col-9 text-left pl-0 align-self-center">Continue With Facebook</div>
                                </div>
                              </a>
                            )}
                            callback={responseFacebook}
                          />
                        </div>

                        <div className="col-12 col-md-12 text-center pt-10">
                          <p className="mb-20 mt-20 terms-section">
                            By signing in you agree the{" "}
                            <a href="/termsandcondition" className="color-2nd">
                              terms and condition
                            </a>{" "}
                            ,{" "}
                            <a href="/privacypolicy" className="color-2nd">
                              {" "}
                              privacy policy
                            </a>{" "}
                            and
                            <a href="/returnpolicy" className="color-2nd">
                              {" "}
                              return policy
                            </a>
                          </p>
                          <span className="linktext">Don't have an account ?</span>
                          <Link to={"/signup"}>
                            <span className="ps-account__link">Sign Up</span>
                          </Link>
                        </div>
                      </div>

                      <div className="mt-20 hide" id="usernameform">
                        <FormikFrom
                          initialValues={initialValues}
                          onSubmit={(values) => {
                            varifyEmailORMobile();
                          }}
                        >
                          <Form>
                            <div className={"ps-form__group"}>
                              <label className="ps-form__label">Enter Email / Mobile Number *</label>
                              <div className="input-group">
                                <Field
                                  className={"form-control ps-form__input"}
                                  name="username"
                                  type="text"
                                  placeholder="Email / Mobile Number"
                                  id="username"
                                  maxLength={50}
                                  onFocus={() => setErrorMsgLogin("")}
                                />
                              </div>
                            </div>
                            <span className={showErrorMsgLogin ? "color-red pt-10" : "color-red hide pt-10"}>
                              {ErrorMsgLogin}
                            </span>
                            <div className="ps-form__submit mt-30">
                              <button className={"ps-btn ps-btn--warning width-100 "}>Continue</button>

                              <button
                                type="button"
                                onClick={backlogin}
                                className={"ps-btn ps-btn--warning width-100 mt-10"}
                              >
                                Back
                              </button>
                            </div>
                          </Form>
                        </FormikFrom>
                      </div>

                      <div className="mt-20 hide" id="loginform">
                        <Formik
                          initialValues={initialValues}
                          validationSchema={validationSchema}
                          onSubmit={(values) => {
                            values.username = user_name;
                            loginUser(values);
                          }}
                        >
                          {(formik) => {
                            const { errors, touched } = formik;
                            if (errors.password) {
                              resetError();
                            }
                            return (
                              <Form>
                                <div className={verify ? "ps-form__group hide" : "ps-form__group"}>
                                  <label className="ps-form__label">Username</label>
                                  <p className="ps-form__title font-size-15">{userName}</p>
                                </div>

                                <div className={"ps-form__group"} id="passworddiv">
                                  <label className="ps-form__label">Password *</label>
                                  <div className="input-group">
                                    <Field
                                      className={
                                        errors.password && touched.password
                                          ? "input-error form-control ps-form__input"
                                          : "form-control ps-form__input"
                                      }
                                      name="password"
                                      type="password"
                                      placeholder="Password"
                                      id="password"
                                    />
                                  </div>
                                  <ErrorMessage name="password" component="span" className="color-red" />
                                </div>
                                <span className={showErrorMsg ? "color-red pt-10" : "color-red hide pt-10"}>
                                  {ErrorMsg}
                                </span>
                                <div className={"row"}>
                                  <div className="col-6 col-md-6 ">
                                    <Link to={"/forgetpassword"}>
                                      <span className="ps-account__link mt-5">Forgot Password</span>
                                    </Link>
                                  </div>
                                  <div className="col-6 col-md-6 text-right">
                                    <a
                                      onClick={() => {
                                        sendOtp();
                                      }}
                                    >
                                      <span className="ps-account__link mt-5">Login With Otp</span>
                                    </a>
                                  </div>
                                </div>
                                <div className="ps-form__submit mt-30">
                                  <button className={"ps-btn ps-btn--warning width-100 "}>Continue</button>

                                  <button
                                    type="button"
                                    onClick={backloginToUser}
                                    className={"ps-btn ps-btn--warning width-100 mt-10"}
                                  >
                                    Back
                                  </button>
                                </div>
                                <div className="row">
                                  <div className="col-12 col-md-12 text-center pt-10">
                                    <span className="linktext">Don't have an account ?</span>
                                    <Link to={"/signup"}>
                                      <span className="ps-account__link">Sign Up</span>
                                    </Link>
                                  </div>
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>

                      <div className="mt-20 hide" id="loginotpform">
                        <Formik
                          validationSchema={validationSchemaMobilelogin}
                          initialValues={initialValues}
                          onSubmit={(values) => {
                            loginUserWithOtp(values);
                          }}
                        >
                          {(formik) => {
                            const { errors, touched } = formik;
                            if (errors.mobileno) {
                              resetError();
                            }
                            return (
                              <Form>
                                <div className="row">
                                  <div className="col-12 col-md-5 pr-0">
                                    <div className="ps-form__group form-group">
                                      <label className="ps-form__label">Country Code*</label>
                                      <div className="input-group">
                                        <SelectCombo
                                          name={"phonecountry"}
                                          options={countrycodearray}
                                          placeholder={"Country"}
                                          setSelectedCountry={setSelectedCountry}
                                          initialValues={initialValues}
                                          value={selectedcountry}
                                        />
                                      </div>
                                      <ErrorMessage name="phonecountry" component="span" className="color-red" />
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-6">
                                    <div className={"ps-form__group"}>
                                      <label className="ps-form__label">Mobile Number *</label>
                                      <div className="input-group">
                                        <Field
                                          className={
                                            errors.password && touched.password
                                              ? "input-error form-control ps-form__input"
                                              : "form-control ps-form__input"
                                          }
                                          name="mobileno"
                                          type="text"
                                          placeholder="Mobile Number"
                                          id="mobileno"
                                          maxLength={"10"}
                                        />
                                      </div>
                                      <ErrorMessage name="mobileno" component="span" className="color-red" />
                                    </div>
                                  </div>
                                </div>
                                <span className={showErrorMsg ? "color-red pt-10" : "color-red hide pt-10"}>
                                  {ErrorMsg}
                                </span>
                                <div className="ps-form__submit mt-30">
                                  <button type="submit" className={"ps-btn ps-btn--warning width-100"}>
                                    Continue
                                  </button>
                                  <button
                                    type="button"
                                    onClick={backlogin}
                                    className={"ps-btn ps-btn--warning width-100 mt-10"}
                                  >
                                    Back
                                  </button>
                                </div>
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Login;
