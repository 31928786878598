import React from "react";

import config from "../../../../config/config.json";
const PopularCategoriesTwo = ({ category, homedetail }) => {
  /*
    To category list view
  */
  let categorylist = [];
  if (category !== null && category !== "") {
    category.map((item, index) => {
      let imagepath = config.apiUrl + config.image_path;
      if (item.imagename !== null && item.imagename !== "") {
        imagepath = imagepath + item.imagename;
      } else {
        imagepath = "/static/img/noproduct-images.png";
      }

      categorylist.push(
        <div className="col-custom">
          <div className="category-card">
            <figure>
              <img src={imagepath} />
              <figcaption>
                <a href={"/shop?parentcategory=" + item.id} className="bordered-title">
                  {item.categoryname}
                </a>
              </figcaption>
            </figure>
          </div>
        </div>
      );
    });
  }

  return (
    <div className="popular-categories-two home-section-category">
      <div className="container">
        <div className="row pb-20 ph-10 title-row">
          <div className="col-8">
            <h3 className="sectionname">{homedetail.displayproductcatsubtitle}</h3>
            <h3 className="section-main-title">{homedetail.displayproductcattitle}</h3>
          </div>
          <div className="col-4 title-action">
            <a href="/shop">
              <div className="d-flex">
                <p className="mr-10">View All</p>
                <i className="fi fi-rr-arrow-circle-right"></i>
              </div>
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="ps-section__content col-12">
              <div className="row">{categorylist}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopularCategoriesTwo;
