import React from "react";

import shopconfig from "../../../../config/shopconfig.json";
import ModuleWhyUsOne from "./ModuleWhyUsOne";

const ModuleWhyUs = () => {
  return (
    <div className="aboutus-section--two">
      <div className="title-two">
        <h2>Why Choose Us</h2>
        <div className="title-line"></div>
        {/* <p>
          Fugiat labore Lorem proident exercitation cupidatat sunt dolore. Qui aute sit sint eiusmod sunt qui dolor sit
          culpa. Lorem mollit reprehenderit est deserunt.
        </p> */}
      </div>
      {/*As first option for why us points*/}
      {/* <div className="whyus-container">
        <div className="row m-0">
          <div className="col-12 col-md-3 content-box">
            <div className="icon col-4 p-0">
              <img src="/static/img/static_image/savemoney.png" />
            </div>
            <div className="text col-8">
              <h5>Greate Value</h5>
              <p>Most populr brands with widest range to product on best price</p>
            </div>
          </div>

          <div className="col-12 col-md-3 content-box">
            <div className="icon col-4 p-0">
              <img src="/static/img/static_image/delivery.png" />
            </div>
            <div className="text col-8">
              <h5>Nationwide Delivery</h5>
              <p>Over 10,000 pincodes serviceable in India</p>
            </div>
          </div>

          <div className="col-12 col-md-3 content-box">
            <div className="icon col-4 p-0">
              <img src="/static/img/static_image/payment.png" />
            </div>
            <div className="text col-8">
              <h5>Secure Payment</h5>
              <p>Partnered with India's most trustable payment solutions</p>
            </div>
          </div>

          <div className="col-12 col-md-3 content-box">
            <div className="icon col-4 p-0">
              <img src="/static/img/static_image/buyerprotection.png" />
            </div>
            <div className="text col-8">
              <h5>Buyer Protection</h5>
              <p>Committed to buyer provide user friendly and smooth shopping Experience </p>
            </div>
          </div>
        </div>

        <div className="row m-0">
          <div className="col-12 col-md-3 content-box">
            <div className="icon col-4 p-0">
              <img src="/static/img/static_image/exchange.png" />
            </div>
            <div className="text col-8">
              <h5>Easy Exchange</h5>
              <p>Provide customers easy exchange and return product within 15 days</p>
            </div>
          </div>

          <div className="col-12 col-md-3 content-box">
            <div className="icon col-4 p-0">
              <img src="/static/img/static_image/handpick.png" />
            </div>
            <div className="text col-8">
              <h5>100% Handpicked</h5>
              <p>Lorem mollit reprehenderit est deserunt</p>
            </div>
          </div>

          <div className="col-12 col-md-3 content-box">
            <div className="icon col-4 p-0">
              <img src="/static/img/static_image/quality.png" />
            </div>
            <div className="text col-8">
              <h5>Assured Quality</h5>
              <p>Provide customer best quality assurance with best services</p>
            </div>
          </div>

          <div className="col-12 col-md-3 content-box">
            <div className="icon col-4 p-0">
              <img src="/static/img/static_image/help1.png" />
            </div>
            <div className="text col-8">
              <h5>365 Help Desk</h5>
              <p className="">
                <i class="fi fi-rr-phone-call d-flex align-items-center">&nbsp; {shopconfig.shop_contactno}</i>
              </p>
            </div>
          </div>
        </div>
      </div> */}
      <ModuleWhyUsOne />
    </div>
  );
};

export default ModuleWhyUs;
