import React, { useState } from "react";

import ProductRepository from "../../../../../api/ProductRepository";

const ModuleDeliveryOption = () => {
  const [pincode, setPincode] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handlePincodeChange = (e) => {
    setPincode(e.target.value);
  };

  const handleCheckDelivery = async () => {
    setLoading(true);
    setError("");
    setSuccessMessage("");

    try {
      const payload = await ProductRepository.getShippingPincode(pincode);

      if (payload === "1") {
        setSuccessMessage("Delivery is available for this pincode!");
      } else {
        setError("Delivery is not available for this pincode.");
      }
    } catch (err) {
      setError("An error occurred while checking the delivery option.");
    }

    setLoading(false);
  };

  return (
    <>
      <div className="delivery-option-container">
        <h3>Delivery Options</h3>
        <div className="d-flex mt-30">
          <input
            type="text"
            placeholder="Enter Your City Pincode"
            value={pincode}
            onChange={handlePincodeChange}
            maxLength={6}
          />
          <button onClick={handleCheckDelivery}>Check</button>
        </div>
        {loading && <p style={{ color: "lightgray", marginTop: "10px" }}>Loading...</p>}

        {error && <p style={{ color: "red", marginTop: "10px" }}>{error}</p>}

        {successMessage && <p style={{ color: "green", marginTop: "10px" }}>{successMessage}</p>}

        <div className="shipping-info-container">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <img src="/static/img/icon/pincode1.png" />
            <span>Free Shipping</span>
          </div>
          <div className="line"></div>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <img src="/static/img/icon/pincode2.png" />
            <span>15 Days Return</span>
          </div>
          <div className="line"></div>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <img src="/static/img/icon/pincode3.png" />
            <span>Cash on Delivery</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModuleDeliveryOption;
