import React, { useState } from 'react'

const ModuleProductFaq = ({ productfaq }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };
  return (
    <div className="faq-container">
      {/* Left Section */}
      <div className="faq-support">
        <h3 >FAQs</h3>
        <p >
          Everything you need to know about the product and billing. Can’t find the answer you’re looking for?
        </p>
      </div>

      {/* Right Section - FAQs */}
      <div className="faq-list">
        {productfaq && productfaq.length > 0 && productfaq.map((faq, index) => (
          <div key={index} className="faq-item">
            <div
              className="faq-question"
              onClick={() => toggleFAQ(index)}
            >
              <h3>{faq.question}</h3>
              <span>{activeIndex === index ? <i className='icon-minus'></i> : <i className='icon-plus'></i>}</span>
            </div>
            {activeIndex === index && (
              <div className="faq-answer">
                <p>{faq.answer}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default ModuleProductFaq