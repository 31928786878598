import { Drawer } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import ActiveLink from "../../../components/elements/basic/ActiveLink";
import ModuleHeaderContactNumber from "../../../components/shared/headers/modules/ModuleHeaderContactNumber";
import ModuleHeaderSwichers from "../../../components/shared/headers/modules/ModuleHeaderSwitcher";
import useEcomerce from "../../../hooks/useEcomerce";
import useGetProducts from "../../../hooks/useGetProducts";
import Collapse from "../../elements/basic/Collapse";
import Logo from "../../elements/basic/Logo";
import MobileSubMenuDropdown from "../../elements/menu/MobileSubMenuDropdown";
import ModuleHeaderActions from "../headers/modules/ModuleHeaderActions";
import useHeader from "../../../hooks/useHeader";

const NavigationBottom = ({ ecomerce, classes, isActive = true }) => {
  const [isMenu, setIsMenu] = useState(false);

  const { username, totalcart, totalwishlist } = useHeader();
  const { getWishlistAndCartCount } = useEcomerce();
  const [isOpen, setIsOpen] = useState(null);

  const navigate = useNavigate();
  const { ckeckLoginToken } = useEcomerce();
  const [isHeaderOne, setIsHeaderOne] = useState(true);

  /*
    To handle toggle 
  */
  const handleToggleAccordion = (index) => {
    if (index !== isOpen) {
      setIsOpen(index);
    } else {
      setIsOpen(null);
    }
  };

  const { mainmenu, getSiteMainMenu } = useGetProducts();

  useEffect(() => {
    ckeckLoginToken();
    getSiteMainMenu();
    setIsMenu(false);
    if (username) {
      getWishlistAndCartCount();
    }
  }, [ecomerce]);

  /*
    To close menu
  */
  function handleCloseMenu(e) {
    e.preventDefault();
    setIsMenu(false);
  }
  /*
    To open menu
    */
  function handleOpenMenu(e) {
    e.preventDefault();
    setIsMenu(true);
  }

  /*
    To view
  */
  let sitemenulist = [];
  if (mainmenu && mainmenu.length > 0) {
    mainmenu.map((items) => {
      if (items.type === "Category") {
        sitemenulist.push(
          <li
            className={`menu__item menu__item--has-children has-mega-menu ${isOpen === items.id ? "active" : ""}`}
            key={items.id}
          >
            {items.displaysubcategory === 1 ? (
              <>
                <a href="#" className="menu__toggle" onClick={(e) => handleToggleAccordion(items.id)}>
                  <span className="menu__text">{items.menuname}</span>
                  <i className="icon-chevron-down menu__icon--down" />
                </a>
                <Collapse
                  elementType="div"
                  isOpen={items.id === isOpen ? true : false}
                  aria-hidden={isOpen ? "false" : "true"}
                  className="sub-menu-wrapper"
                >
                  <ul className={"menu--accordion"}>
                    <MobileSubMenuDropdown source={items} setIsMenu={setIsMenu} />
                  </ul>
                </Collapse>
              </>
            ) : (
              <a onClick={(e) => redirectPage(e, items.url)}>
                <span className="menu__text mt-10 mb-10 title-font font-size-20">{items.menuname}</span>
              </a>
            )}
          </li>
        );
      } else {
        sitemenulist.push(
          <li key={items.menuname}>
            <a onClick={(e) => redirectPage(e, items.url)}>
              <span className="menu__text mt-10 mb-10 title-font font-size-20">{items.menuname}</span>
            </a>
          </li>
        );
      }
    });
  }

  /*
    To close menu
  */
  const redirectPage = (e, link) => {
    navigate(link);
    handleCloseMenu(e);
  };
  return (
    <>
      <nav className={`navigation--bottom ${classes} ${isActive && "active"}`}>
        <div className="navigation__content">
          {isHeaderOne === false ? (
            <a className="navigation__item" onClick={(e) => handleOpenMenu(e)}>
              <i className="icon-menu"></i>
            </a>
          ) : (
            ""
          )}
          <ActiveLink activeClassName="active" href="/">
            <a className="navigation__item">
              <i className="icon-home2"></i>
            </a>
          </ActiveLink>
          <ActiveLink activeClassName="active" href="/profile">
            <a className="navigation__item">
              <i className="icon-user"></i>
            </a>
          </ActiveLink>
          <ActiveLink activeClassName="active" href="/wishlist">
            <a className="navigation__item cart">
              <i className="icon-heart"></i>
              {totalwishlist > 0 && <span>{totalwishlist}</span>}
            </a>
          </ActiveLink>
          <ActiveLink activeClassName="active" href="/cart">
            <a className="navigation__item cart">
              <i className="icon-bag2"></i>
              {totalcart > 0 && <span>{totalcart}</span>}
            </a>
          </ActiveLink>
        </div>
      </nav>
      <Drawer
        className="ps-panel--mobile mobile-home "
        closable={false}
        placement="left"
        onClose={(e) => handleCloseMenu(e)}
        visible={isMenu}
      >
        <div className="ps-drawer ps-drawer--with-menu homemenu">
          <div className="ps-drawer__header pull-right">
            <a href="#" className="ps-drawer__close" onClick={(e) => handleCloseMenu(e)}>
              <i className="icon-cross"></i>
            </a>
          </div>
          <div className="ps-drawer__wrapper">
            <div className="ps-drawer__menu pt-40 pb-10">
              <div className="pb-30 pt-20 text-center">
                <Logo />
              </div>
              <ul className={"menu--accordion"}>{sitemenulist}</ul>
            </div>
            <div className="ps-drawer__footer">
              <figure className="mobile-header-swichers">
                <ModuleHeaderSwichers username={username} />
              </figure>
              <figure>
                <ModuleHeaderActions username={username} />
              </figure>
              <figure>
                <ModuleHeaderContactNumber />
              </figure>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default connect((state) => state)(NavigationBottom);
