import React, { useState } from "react";
import { Link } from "react-router-dom";

import Logo from "../../elements/basic/Logo";
import ModuleHeaderContactNumber from "../headers/modules/ModuleHeaderContactNumber";

const HeaderMenuDrawer = ({ fullname, totalcart, totalwishlist, handleCloseMenu, mainmenu }) => {
  const [submenuOpen, setSubmenuOpen] = useState(false);

  const toggleSubmenu = (id) => {
    setSubmenuOpen((prev) => (prev === id ? null : id));
  };

  return (
    <>
      <div className="header-one--menu-drawer">
        <div className="container pr-0">
          <div className="header-top">
            <div className="close-button">
              <i class="fi fi-rr-cross" onClick={handleCloseMenu}></i>
            </div>
            <div className="logo">
              <Logo height="60" />
            </div>
          </div>
          <div className="header-body-top">
            <div className="user-action-container">
              <div className="user">
                <h5 className="mb-0">Welcome</h5>
                <p>{fullname}</p>
              </div>
              <div className="action">
                <Link to={"/profile"}>
                  <i class="fi fi-rr-user"></i>
                </Link>
                <div className="icon-with-badge">
                  <Link to={"/wishlist"}>
                    <i class="fi fi-rr-heart"></i>
                    <span>{totalwishlist}</span>
                  </Link>
                </div>
                <div className="icon-with-badge">
                  <Link to={"/cart"}>
                    <i class="fi fi-rr-shopping-cart"></i>
                    <span>{totalcart}</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="header-body-bottom">
            <div className="menu-container">
              <ul>
                {mainmenu &&
                  mainmenu.length > 0 &&
                  mainmenu.map((menuItem) => (
                    <React.Fragment key={menuItem.id}>
                      <li onClick={() => toggleSubmenu(menuItem.id)}>
                        <Link to={menuItem.url}>{menuItem.menuname}</Link>
                        {menuItem.subcategories && menuItem.subcategories.length > 0 && (
                          <div className="arrow">
                            {submenuOpen === menuItem.id ? (
                              <i class="fi fi-rr-angle-small-up"></i>
                            ) : (
                              <i class="fi fi-rr-angle-small-down"></i>
                            )}
                          </div>
                        )}
                      </li>
                      {submenuOpen === menuItem.id && menuItem.subcategories && menuItem.subcategories.length > 0 && (
                        <li className="submenu-container">
                          <ul className="submenu">
                            {menuItem.subcategories
                              .filter(
                                (submenu, index, self) =>
                                  index === self.findIndex((s) => s.categoryname === submenu.categoryname)
                              )
                              .map((submenu) => (
                                <li key={submenu.id}>
                                  <Link to={submenu.url}>{submenu.categoryname}</Link>
                                </li>
                              ))}
                          </ul>
                        </li>
                      )}
                    </React.Fragment>
                  ))}
              </ul>
            </div>
          </div>
          <div className="header-bottom">
            <ModuleHeaderContactNumber />
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderMenuDrawer;
