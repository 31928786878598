import React, { useState } from 'react';

const sizeGuideData = {
  in: [
    { size: 'S', chest: 38, length: 28, sleeve: 7.75 },
    { size: 'M', chest: 40, length: 28.5, sleeve: 8 },
    { size: 'L', chest: 42, length: 29, sleeve: 8.25 },
    { size: 'XL', chest: 44, length: 29.5, sleeve: 8.5 },
    { size: '2XL', chest: 46, length: 30, sleeve: 8.75 },
  ],
  cm: [
    { size: 'S', chest: 96.52, length: 71.12, sleeve: 19.68 },
    { size: 'M', chest: 101.6, length: 72.39, sleeve: 20.32 },
    { size: 'L', chest: 106.68, length: 73.66, sleeve: 20.96 },
    { size: 'XL', chest: 111.76, length: 74.93, sleeve: 21.59 },
    { size: '2XL', chest: 116.84, length: 76.2, sleeve: 22.22 },
  ],
};

const ModuleSizeGuide = ({ closeDrawer }) => {
  const [sizeMeasure, setSizeMeasure] = useState("in");

  const handleToggle = (unit) => {
    setSizeMeasure(unit);
  };

  const category = 'female';

  const measureImage = category === 'male'
    ? '/static/img/static_image/male.png'
    : '/static/img/static_image/female.png';

  const measurePoints = [
    { point: 'a', content: "SHOULDER - Measure shoulder width by taking the distance from the outer edge of one shoulder to the outer edge of the other shoulder." },
    { point: 'b', content: "CHEST - Measure chest by wrapping a tape measure around the fullest part of the chest, ensuring it is snug but not too tight." },
    { point: 'c', content: "WAIST - Measure waist by wrapping a tape measure around the natural waistline, which is typically the narrowest part of the torso, above the belly button and below the rib cage." },
    { point: 'd', content: "HIP - Measure hip by wrapping a tape measure around the fullest part of the hips, typically around the widest point of the buttocks." },
    { point: 'e', content: "OUTSEAM LENGTH - Measure outseam length by measuring from the top of the waistband to the bottom hem of the garment along the outside leg." },
    { point: 'f', content: "INSEAM LENGTH - - Measure inseam length by measuring from the crotch seam to the bottom hem of the garment along the inside leg." },
  ];

  return (
    <>
      <div className="size-guide-container">
        <div className='close-btn'>
          <i className='icon-cross' onClick={() => closeDrawer()}></i>
        </div>
        <div className='size-guide-content'>
          <div className='title'>Size Guide</div>
          <div className='size-option'>
            <button
              className={`size ${sizeMeasure === 'in' ? 'active' : ''}`}
              onClick={() => handleToggle('in')}
            >
              in
            </button>
            <button
              className={`size ${sizeMeasure === 'cm' ? 'active' : ''}`}
              onClick={() => handleToggle('cm')}
            >
              cm
            </button>
          </div>
        </div>
        {/* Size Table */}
        <table className="size-table">
          <caption className='text-center'>All measurements of the garment are {sizeMeasure === 'in' ? "in inches" : "in cms"}</caption>
          <thead>
            <tr>
              <th>Size</th>
              <th>To Fit Chest</th>
              <th>Length</th>
              <th>Sleeve</th>
            </tr>
          </thead>
          <tbody>
            {sizeGuideData[sizeMeasure].map((item, index) => (
              <tr key={index}>
                <td>{item.size}</td>
                <td>{item.chest}</td>
                <td>{item.length}</td>
                <td>{item.sleeve}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className='measure-image'>
          <img src='/static/img/static_image/sizechart.png' />
        </div>

        <div className='instructions mb-30'>
          <div className='mb-20'>
            <div className='title'>How to measure:</div>
            <div className='line'></div>
          </div>
          <div className='content'>
            {/* <h5>HOW TO TAKE MY MEASUREMENTS</h5> */}
            <p>To choose the correct size for you, measure your body as follows:</p>
            <div className='d-flex mt-25'>
              <div className='col-4 p-0'>
                <img src={measureImage} alt={`${category} measurement`} />
              </div>
              <div className='col-8 p-0'>
                {measurePoints.map(item =>
                  <li>
                    <div className='point'><span>{item.point}</span></div>
                    <div>{item.content}</div>
                  </li>
                )}

              </div>
            </div>
            <div className='d-flex mb-20 mt-20'>
              <div className='point-below'><span>!</span></div>
              <span className='text'>All measurements are body measurements</span>
            </div>
            <div className='d-flex'>
              <div className='point-below'><span>!</span></div>
              <span className='text'>Sizes may rarely vary in the range of +/- 0.5 inches</span>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default ModuleSizeGuide