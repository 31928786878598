import { Button, Dropdown, Menu, Select } from "antd";
import React, { useEffect, useState } from "react";

import LocalstorageParam from "../../../../pages/common/LocalstorageParam";

const { Option } = Select;

const ModuleHeaderSwichers = () => {
  const [currencyname, setCurrencyName] = useState("RS");

  /*
    To set local storage parameter in json
  */
  var localparam = LocalstorageParam();

  /*
    To set username
  */
  let username = "";
  if (
    localparam !== "" &&
    localparam.decoded !== "" &&
    localparam.decoded.firstname !== null &&
    localparam.decoded.firstname !== ""
  ) {
    username = localparam.decoded.firstname.charAt(0) + localparam.decoded.lastname.charAt(0);
  }

  useEffect(() => {
    addClass();
    if (localStorage.getItem("currencyname")) {
      setCurrencyName(localStorage.getItem("currencyname"));
    }
  }, []);

  /*
    To class add or remove
  */
  const addClass = () => {
    const headerElement = document.getElementById("header_switchers");
    if (headerElement) {
      if (username) {
        headerElement.classList.add("padding-right-55");
      } else {
        headerElement.classList.remove("padding-right-55");
      }
    }
  };

  /*
    To set currency
  */
  const setCurrency = (id, name) => {
    localStorage.setItem("currencyid", id);
    localStorage.setItem("currencyname", name);
    setCurrencyName(name);
    window.location.reload();
  };

  /*
    To view
  */
  const currencyItems = (
    <Menu>
      <a onClick={(e) => setCurrency("1", "RS")} className="color-black">
        <Menu.Item>RS</Menu.Item>
      </a>
      <a onClick={(e) => setCurrency("4", "USD")}>
        <Menu.Item>USD</Menu.Item>
      </a>
      {/* <a onClick={(e) => setCurrency("3", "CAD")}>
        <Menu.Item>CAD</Menu.Item>
      </a> */}
    </Menu>
  );

  return (
    <div className="header__switchers" id="header_switchers">
      <div className="header__switcher">
        <Dropdown overlay={currencyItems} placement="bottomLeft">
          <Button>
            {currencyname} <i className="icon-chevron-down"></i>
          </Button>
        </Dropdown>
      </div>
    </div>
  );
};

export default ModuleHeaderSwichers;
