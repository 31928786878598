import React, { useEffect } from "react";

import BreadCrumb from "../../components/elements/BreadCrumb";
import Container from "../../components/layouts/Container";
import GoogleCaptcha from "../../components/common/GoogleCaptcha";

const ReturnPolicy = () => {
  /*
    To breadcrumb
  */
  const breadcrumb = [
    {
      text: "Home",
      url: "/",
    },
    {
      text: "Return Policy",
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <GoogleCaptcha />
      <div className="ps-page  form-loader">
        <div className="ps-section__overlay">
          <div className="ps-section__loading"></div>
        </div>
        <div className="container">
          <div className="ps-page__header">
            <BreadCrumb breacrumb={breadcrumb} />
            <h1 className="ps-page__heading">Return Policy</h1>
          </div>
          <div className="ps-page__content ps-account">
            <div className="row mb-100">
              <div className="col-12 pt-20">
                <span className="title-font">
                  Thank you for shopping at the plus4fitness.com. We strive to provide you with high-quality products
                  and excellent customer service. If you are not completely satisfied with your purchase, we offer a
                  return policy to ensure your satisfaction. Please review the following guidelines for returns:
                </span>
              </div>
              <div className="col-12 pt-20">
                <h4> 1. Eligibility for Return</h4>
                <span className="title-font">
                  <p>a. Products must be returned within 7 days from the date of purchase. </p>
                  <p>b. The products must be unused, undamaged, and in their original packaging. </p>
                  <p>
                    c. Perishable or consumable items are not eligible for return, unless they are damaged or defective
                    upon arrival.
                  </p>
                  <p>
                    d. Personalized or customized items cannot be returned, unless there is a manufacturing defect or
                    error on our part.
                  </p>
                </span>
              </div>
              <div className="col-12 pt-20">
                <h4> 2. Return Process</h4>
                <span className="title-font">
                  <p>
                    a. To initiate a return, please contact our customer support team at <b>plus4fitness@gmail.com</b>{" "}
                    Provide your order details and the reason for the return.{" "}
                  </p>
                  <p>
                    b. Our customer support team will guide you through the return process and provide you with a return
                    authorization if your return is eligible.{" "}
                  </p>
                  <p>
                    c. Package the items securely in their original packaging, including any accessories or promotional
                    items that came with the product.{" "}
                  </p>
                  <p>
                    d. Include the return authorization number and a copy of the original receipt or proof of purchase.{" "}
                  </p>
                  <p>
                    e. Ship the package back to us using a traceable and insured shipping method. You will be
                    responsible for the return shipping costs, unless the return is due to a defective or incorrect
                    item.{" "}
                  </p>
                </span>
              </div>
              <div className="col-12 pt-20">
                <h4> 3. Refunds</h4>
                <span className="title-font">
                  <p>
                    a. Once we receive and inspect the returned items, we will process the refund based on the original
                    payment method used for the purchase
                  </p>
                  <p>
                    b. Refunds will be issued for the purchase price of the returned items, excluding any shipping
                    charges or additional fees.{" "}
                  </p>
                  <p>
                    c. Please note that it may take several business days for the refund to appear in your account,
                    depending on your bank or payment processor.
                  </p>
                </span>
              </div>
              <div className="col-12 pt-20">
                <h4> 4. Exchanges</h4>
                <span className="title-font">
                  <p>
                    We currently do not offer direct exchanges for returned items. If you wish to exchange a product,
                    please follow the return process for a refund and place a new order for the desired item
                  </p>
                </span>
              </div>
              <div className="col-12 pt-20">
                <h4> 5. Damaged or Defective Items</h4>
                <span className="title-font">
                  <p>
                    a. If you receive a damaged or defective item, please contact our customer support team immediately
                  </p>
                  <p>
                    b. We may request photographic evidence or other proof of the damage or defect before authorizing a
                    return
                  </p>
                  <p>
                    c. In case of a verified damaged or defective item, we will provide a refund, including any shipping
                    charges.
                  </p>
                </span>
              </div>
              <div className="col-12 pt-20">
                <h4> 6. Non-Returnable Items</h4>
                <span className="title-font">
                  <p>The following items are not eligible for return:</p>
                  <p>• Perishable or consumable items (unless damaged or defective)</p>
                  <p>
                    • Personalized or customized items (unless there is a manufacturing defect or error on our part)
                  </p>
                </span>
              </div>
              <div className="col-12 pt-20">
                <h4> 7. Changes to the Return Policy: </h4>
                <span className="title-font">
                  <p>
                    We reserve the right to modify or update this return policy at any time. Any changes will be
                    effective when posted on our website.
                  </p>
                </span>
              </div>
              <div className="col-12 pt-20">
                <span className="title-font">
                  <p>
                    If you have any further questions or need assistance with your return, please contact our customer
                    support team at <b>plus4fitness@gmail.com</b> We are here to help you.
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default ReturnPolicy;
