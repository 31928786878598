import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import home from "../../../../api/home";
import CustomerReviewOne from "../../../../components/partials/homepages/sections/reviews/CustomerReviewOne";

const ModuleTestimonial = ({ ecomerce }) => {
  const [homedata, setHomeData] = useState(null);

  useEffect(() => {
    getHomeData();
  }, [ecomerce]);

  /*
    To get home data
  */
  const getHomeData = async () => {
    const responseData = await home.getHomeData();
    console.log(responseData);
    if (responseData) {
      setHomeData(responseData.data.payload);
    }
  };

  return (
    <div className="aboutus-section--three">
      <div className="title-three">
        <h2>Testimonials</h2>
        <div className="title-line"></div>
      </div>
      {homedata && <CustomerReviewOne customerreview={homedata.customerreview} homedetail={homedata.homedetail} />}
    </div>
  );
};

export default connect((state) => state)(ModuleTestimonial);
