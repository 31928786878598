import { Drawer } from "antd";
import React, { useEffect, useState } from "react";
import { Rating } from "react-simple-star-rating";
import { Form } from "formik";

import config from "../../../../../config/config.json";
import useFilter from "../../../../../hooks/useFilter";
import ModuleCustomerReviewDrawer from "./ModuleCustomerReviewDrawer";
import LocalstorageParam from "../../../../../pages/common/LocalstorageParam";
import ProductRepository from "../../../../../api/ProductRepository";
import FormikFrom from "../../../form/FormikFrom";

const ModuleProductReviewAndRating = ({ images, productReview, product, productviewcount }) => {
  const [ratingsData, setRatingsData] = useState([]);
  const { isMenu, closeFilters, showFilters } = useFilter();

  const [rating, setRating] = useState(0);
  const [disable, setDisable] = useState(false);
  const [decription, setDecription] = useState("");
  const [title, setTitle] = useState("");

  const [ratingError, setRatingError] = useState("");
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  const productData = (productviewcount && productviewcount.length > 0 && productviewcount[0]) || {};

  const averageRating = productData.averagerating || "0.0";
  const totalReviews = productData.totalreviews || 0;
  const count = (productData.count && productData.count) || {};

  useEffect(() => {
    const fetchedRatingsData = [
      { label: "Excellent", count: count.countrange5 || 0, className: "excellent" },
      { label: "Very Good", count: count.countrange4 || 0, className: "very-good" },
      { label: "Good", count: count.countrange3 || 0, className: "good" },
      { label: "Average", count: count.countrange2 || 0, className: "average" },
      { label: "Poor", count: count.countrange1 || 0, className: "poor" },
    ];
    setRatingsData(fetchedRatingsData);
  }, []);

  let imagepath = config.apiUrl + config.image_path;
  let noimage = "/static/img/static_image/user_image.png";

  /*
    To set local storage parameter in json
  */
  var localparam = LocalstorageParam();

  /*
    To set rating in state variable
  */
  const handleRating = (rate) => {
    setRating(rate);
    setRatingError("");
  };

  /*
    To save product review
  */
  const saveRating = async () => {
    setRatingError("");
    setTitleError("");
    setDescriptionError("");
    document.getElementById("successmsg").classList.add("hide");

    let isValid = true;
    if (rating === 0) {
      setRatingError("Please select a rating");
      isValid = false;
    }
    if (!title) {
      setTitleError("Please enter a title");
      isValid = false;
    }
    if (!decription) {
      setDescriptionError("Please enter a description");
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    document.getElementById("successmsg").classList.add("hide");
    setDisable(true);

    const reviewobject = {
      productid: product.id,
      rating: rating,
      title: title,
      review: decription,
    };

    const res = await ProductRepository.saveProductReview(reviewobject);
    if (res.data.status === 200) {
      document.getElementById("successmsg").classList.remove("hide");
      setTitle("");
      setDecription("");
      setRating(0);
      handleRating(0);

      setTimeout(() => {
        setDisable(false);
      }, 500);
    } else {
      setDisable(false);
    }
  };
  // useEffect(() => {}, [rating]);

  if (ratingsData.length === 0) {
    return <p>Loading ratings...</p>;
  }

  const totalRatings = ratingsData.reduce((total, rating) => total + rating.count, 0);

  return (
    <>
      <div className="product-review-rating-container">
        <div className="review-rating-title">
          <h3>Product Ratings and Reviews</h3>
        </div>

        <div className=" row">
          <div className="col-md-6 left-container">
            <div className="rating-section row">
              <div className="col-12 col-md-4 d-flex flex-column justify-content-center align-items-center mb-20">
                <h1>
                  {averageRating}
                  <i className="fi fi-rr-star"></i>
                </h1>
                <span>{totalReviews} Review(s)</span>
              </div>
              <div className="col-12 col-md-8 ratings-container justify-content-center align-items-center">
                {ratingsData.map((rating) => {
                  const percentage = totalRatings > 0 ? (Number(rating.count) / totalRatings) * 100 : 0;
                  return (
                    <div className="rating-bar" key={rating.label}>
                      <span>{rating.label}</span>
                      <div className="progress">
                        <div className={`progress-bar ${rating.className}`} style={{ width: `${percentage}%` }}></div>
                      </div>
                      <span className="rating-count">{rating.count}</span>
                    </div>
                  );
                })}
              </div>
            </div>

            <hr />

            <div className="row customer-review-container">
              <div className="col-md-12">
                <div className="pr-10 review-padding-right">
                  {productReview && productReview.productreviewlist && productReview.productreviewlist.length > 0 ? (
                    <>
                      <div className="subtitle-container">
                        <div>
                          <h3>Customer Reviews ({totalReviews})</h3>
                        </div>
                        <div>
                          <span className="view-all pr-10" onClick={showFilters}>
                            View All
                          </span>
                        </div>
                      </div>

                      <div className="customer-review">
                        <div className="userinfo-container">
                          <div className="d-flex align-items-center">
                            <img
                              src={
                                productReview.productreviewlist[0].imagename
                                  ? `${imagepath}${productReview.productreviewlist[0].imagename}`
                                  : noimage
                              }
                            />
                            <div className="d-flex flex-column">
                              <span>
                                {productReview.productreviewlist[0].firstname}{" "}
                                {productReview.productreviewlist[0].lastname}
                              </span>
                              <span className="post-time">
                                Posted on: {productReview.productreviewlist[0].reviewdate}
                              </span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-center">
                            <div className="rating">{productReview.productreviewlist[0].rating} ⭐</div>
                          </div>
                        </div>
                        <div className="userreview-container mb-10">
                          <p>{productReview.productreviewlist[0].review}</p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div>Be the first to add the review</div>
                  )}
                </div>
              </div>
            </div>
            <hr className="mobile" />
          </div>

          <div className="col-md-6 right-container">
            <div className="customer-review-container review-form-container">
              <div className="write-review">
                {localparam !== "" &&
                localparam.decoded !== "" &&
                localparam.decoded.username !== null &&
                localparam.decoded.username !== "" ? (
                  <div className="pl-10 review-padding-left">
                    <>
                      <h3>Write Review </h3>
                      <FormikFrom
                        initialValues={{
                          rating: 0,
                          title: "",
                          description: "",
                        }}
                        onSubmit={saveRating}
                      >
                        <Form>
                          <div className="customer-review-form">
                            <div className="row img-form-container">
                              <div className="col-md-12">
                                <div className="mb-30">
                                  <span>
                                    <Rating initialValue={rating} size={30} allowFraction onClick={handleRating} />
                                  </span>
                                  {ratingError && <div className="error-message color-red">{ratingError}</div>}
                                </div>
                                <div className="mb-20">
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder={"Review Title"}
                                    value={title}
                                    onChange={(e) => {
                                      setTitle(e.target.value);
                                      setTitleError("");
                                    }}
                                  />
                                  {titleError && <div className="error-message color-red">{titleError}</div>}
                                </div>
                                <div className="review mb-10">
                                  <textarea
                                    className="form-control"
                                    id="exampleFormControlTextarea1"
                                    rows="3"
                                    placeholder="Your review..."
                                    value={decription}
                                    onChange={(e) => {
                                      setDecription(e.target.value);
                                      setDescriptionError("");
                                    }}
                                    maxLength="500"
                                  ></textarea>
                                  {descriptionError && (
                                    <div className="error-message color-red">{descriptionError}</div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="d-grid align-items-center justify-content-center review-button w-100">
                              <button className="w-100 btn btn-lg" onClick={saveRating} disabled={disable}>
                                Add Review
                              </button>
                            </div>
                            <div className="color-green hide mt-30" id="successmsg">
                              Your review has been submitted successfully
                            </div>
                            <div id="errormsg" className="color-red"></div>
                          </div>
                        </Form>
                      </FormikFrom>
                    </>
                  </div>
                ) : (
                  <>
                    <div className="login-review">
                      <h3>Your feedback matters. Please share your valuable feedback.</h3>
                      <div>
                        <i class="fi fi-rr-star"></i>
                        <i class="fi fi-rr-star"></i>
                        <i class="fi fi-rr-star"></i>
                        <i class="fi fi-rr-star"></i>
                        <i class="fi fi-rr-star"></i>
                      </div>
                      <p>Login to share your review</p>
                      <a href="/login">Login</a>
                    </div>
                  </>
                )}
              </div>

              {isMenu && (
                <Drawer
                  className="ps-panel--mobile mobile-home"
                  closable={false}
                  placement="right"
                  onClose={closeFilters}
                  visible={isMenu}
                  width={700}
                >
                  <ModuleCustomerReviewDrawer
                    images={images}
                    closeDrawer={closeFilters}
                    productReview={productReview}
                    product={product}
                  />
                </Drawer>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModuleProductReviewAndRating;
