import React from "react";

const ModuleProductTags = ({ product }) => {
  return (
    <>
      {product && product.tagslist && product.tagslist !== null && product.tagslist.length > 0 && (
        <div className="tags-container container p-0 mt-30">
          <div className="tag-title">
            <h3>Tags</h3>
            <div className="tag-name">
              {product.tagslist.map((tag) => (
                <span className="badge">{tag.tagsname}</span>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModuleProductTags;
