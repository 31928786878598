import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ProductRepository from "../../../api/ProductRepository";
import config from "../../../config/config.json";

const FormSearchHeader = () => {
  const [keyword, setKeyword] = useState("");
  const [product, setProduct] = useState(null);

  useEffect(() => {
    document.body.addEventListener("click", closeSidemenu);
  }, []);

  /*
    To close side menu 
  */
  let closeSidemenu = () => {
    setKeyword("");
  };

  const navigate = useNavigate();

  /*
    To set search keyword on state variable 
  */
  function handleSetKeyword(e) {
    e.preventDefault();
    setKeyword(e.target.value);
    if (e.target.value) {
      getSearchProduct(e.target.value);
    }
  }

  /*
    To redirect to shop url 
  */
  function handleSubmit(e) {
    e.preventDefault();
    redirectProduct();
    setKeyword("");
  }
  /*
    To redirect url 
  */
  const redirectProduct = () => {
    if (keyword && keyword !== null && keyword !== "") {
      navigate("/shop?pageno=1&searchkey=" + keyword);
    }
  };

  /*
    To get search product
  */
  async function getSearchProduct(search) {
    var object = new Object();
    object["search"] = search;
    const productdata = await ProductRepository.getSearchProduct(object);
    if (productdata) {
      setProduct(productdata);
    } else {
      return null;
    }
  }

  /*
    To view
  */
  let productlist = [];
  let imageURL, imagepath;
  if (keyword !== null && keyword !== "") {
    if (product && product !== null && product !== "" && product.length > 0) {
      product.map((item) => {
        imagepath = config.apiUrl + config.image_path;
        if (item.imagename) {
          imageURL = `${imagepath}${item.imagename}`;
        } else {
          imageURL = "/static/img/noproduct-images.png";
        }
        productlist.push(
          <li>
            <a href={`/product/${item.uniqueid}`}>
              <div className="row">
                <div className="col-2 pr-0">
                  <img src={imageURL} alt="" className="search-image" />
                </div>
                <div className="col-10 pl-0 align-self-center">{item.productname}</div>
              </div>
            </a>
          </li>
        );
      });
    } else {
      productlist.push(<li className="text-center">No Product Found</li>);
    }
  }

  return (
    <>
      <form onSubmit={(e) => handleSubmit(e)} className="header__search-form">
        <div className="ps-search-table">
          <div className="input-group">
            <input
              className="form-control ps-input"
              type="text"
              placeholder="Search for products"
              onChange={(e) => handleSetKeyword(e)}
              value={keyword}
            />
            <div className="input-group-append">
              <a href="#" onClick={(e) => handleSubmit(e)}>
                <i className="fi fi-rr-search"></i>
              </a>
            </div>
          </div>
        </div>
        {keyword ? (
          <div className="product-search">
            <ul>{productlist}</ul>
          </div>
        ) : (
          ""
        )}
      </form>
    </>
  );
};

export default FormSearchHeader;
