import { Button, Dropdown, Menu } from "antd";
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import config from "../../../../config/config.json";
import { UrlProduct } from "../../../../pages/product/LoadProduct";

const ModuleShopPaginationRange = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  let upperlimit = searchParams.get("upperlimit");

  if (!upperlimit) {
    upperlimit = config.upperlimit;
  }
  const [productupperlimit, setUpperlimit] = useState(upperlimit);

  /*
    To redirect to shop url
  */
  const setProductUpperLimit = (item) => {
    setUpperlimit(item);
    redirectProduct(item);
  };

  /*
    To redirect to url
  */
  const redirectProduct = (item) => {
    navigate(UrlProduct(searchParams, "upperlimit") + "&upperlimit=" + item);
  };

  /*
    To view
  */
  let ranges = [20, 50, 100];
  const paginationRangeItems = ranges.map((item) => (
    <Menu.Item key={item}>
      <a onClick={(e) => setProductUpperLimit(item)}>{item}</a>
    </Menu.Item>
  ));
  const view = <Menu>{paginationRangeItems}</Menu>;
  return (
    <div className="ps-shop__pagination-range">
      <span className="action-label">Show</span>
      <Dropdown overlay={view} placement="bottomLeft" className="ps-dropdown-toggle">
        <Button className="pt-0">
          <span className="dark-blue mr-10">{productupperlimit}</span> <i className="icon-chevron-down"></i>
        </Button>
      </Dropdown>
    </div>
  );
};

export default ModuleShopPaginationRange;
